<template>
  <b-row class="mb-5">
    <b-col md="4">
      <section :style="{
               'border-radius': '5px',
              'background-size': 'cover',
              'background-position': 'center',
              'background-image':
           'url(' + item.image + ')' }"
               class="w-100 h-250px position-relative cursor-pointer">
      </section>
    </b-col>
    <b-col md="6">
      <h5 class="text-primary font-size-22 mb-1 font-weight-bold">{{ item.title }}</h5>
      <p>{{item.description}}</p>
    </b-col>
    <b-col md="2" class="d-flex align-items-end justify-content-end">
      <button class="bg-transparent border border-warning font-bold py-2 px-4 iq-border-radius-5" @click="$router.push({name: item.routeName})">
        <span>{{ $t('endUser.more') }}</span>
      </button>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: ['item']
}
</script>
<style>

</style>
